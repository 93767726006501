import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Tabs } from '@mantine/core';
import { IconFingerprint, IconMoneybag, IconUser } from '@tabler/icons';

import './SettingsNavigation.scss';
import { selectCurrentView, selectShouldConfirm, setCurrentView, setShouldConfirm } from 'pages/settings/SettingsSlice';
import { SettingsView } from 'pages/settings/enum';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useAuth0 } from '@auth0/auth0-react';

// const navigationOptions: {
//   [path: string]: {
//     label: string;
//     icon: TablerIcon;
//   };
// } = {
//   [SettingsView.BASIC]: {
//     label: 'Basic info',
//     icon: IconUser,
//   },
//   [SettingsView.IDENTITY]: {
//     label: 'Identity',
//     icon: IconFingerprint,
//   },
// };

const SettingsNavigation: React.FC = () => {
  const navigate = useNavigate();
  const shouldConfirm = useAppSelector(selectShouldConfirm);
  const currentView = useAppSelector(selectCurrentView);
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth0();
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    const view = location.pathname.split('/')[2];
    dispatch(setCurrentView(view as SettingsView));
  }, [dispatch, location.pathname]);

  const handleTabChange = useCallback(
    (view: SettingsView) => {
      dispatch(setCurrentView(view));
      navigate(view);
    },
    [dispatch, navigate]
  );

  return (
    <Tabs className="settings-navigation" value={currentView} onTabChange={handleTabChange}>
      <Tabs.List>
        <Tabs.Tab
          key="basic"
          icon={<IconUser size={24} stroke={2} />}
          className="settings-navigation__tab"
          value="basic"
        >
          Basic Info
        </Tabs.Tab>
        {/* !shouldConfirm && (
          <Tabs.Tab
            key="identity"
            icon={<IconFingerprint size={24} stroke={2} />}
            className="settings-navigation__tab"
            value="identity"
          >
            Identity
          </Tabs.Tab>
        ) */}
        {/* {isAuthenticated && !isAuthLoading && (
          <Tabs.Tab
            key="portfolio"
            icon={<IconMoneybag size={24} stroke={2} />}
            className="settings-navigation__tab"
            value="portfolio"
          >
            Personal Portfolio
          </Tabs.Tab>
        )} */}
      </Tabs.List>
    </Tabs>
  );
};

export default SettingsNavigation;
