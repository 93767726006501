import React from 'react';
import { Group, Title } from '@mantine/core';
import InvestmentInput from 'components/Common/InvestmentInput';

interface InvestmentFormProps {
  investmentValue: number | undefined;
  setInvestmentValue: (value: number) => void;
  currency: string;
  minInvestment: number;
}

const InvestmentForm: React.FC<InvestmentFormProps> = ({ investmentValue, setInvestmentValue, currency, minInvestment }) => (
  <div>
    <Title order={1} size="2rem" weight={550}>
      Complete your investment
    </Title>
    <Group mt="md">
      <InvestmentInput
        currency={currency}
        minInvestment={minInvestment}
        investmentValue={investmentValue || 0}
        setInvestmentValue={setInvestmentValue}
        label="Investment amount"
      />
    </Group>
  </div>
);

export default InvestmentForm;
