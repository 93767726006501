import React from 'react';
import { Stack, Title, Text, Card, Box } from '@mantine/core';
import './Wired.scss';

const WiredInstruction:React.FC<{ commitmentAmount?: string }> = ({ commitmentAmount }) => (
    <Card>
       <Stack>
           <Title order={4} weight={550}>Wiring instructions{ commitmentAmount && `: please transfer $${commitmentAmount}`}</Title>
           <Text color="dimmed" mt={4} mb={-15}>Transfer funds before the deal closes to avoid cancellation</Text>

           <Text size="sm" mt={4}>- Please ensure all banking fees, charges or FX spreads be charged as <b>OUR</b> only.</Text>
           <Text size="sm" mt={-15}>- Please remit funds in USD only</Text>
       <Box className="wired-bank-box">
           <Box className="wired-bank-info">
               <Text size="sm">Account Currency</Text>
               <Text size="sm" weight={550}>USD</Text>
           </Box>
           <Box className="wired-bank-info">
               <Text size="sm">Beneficiary Name</Text>
               <Text size="sm" weight={550}>Tokenfolio Limited</Text>
           </Box>
           <Box className="wired-bank-info">
               <Text size="sm">Beneficiary Account Number</Text>
               <Text size="sm" weight={550}>56790588</Text>
           </Box>
           <Box className="wired-bank-info">
               <Text size="sm">Beneficiary Bank</Text>
               <Text size="sm" weight={550}>Barclays Bank PLC</Text>
           </Box>
           <Box className="wired-bank-info">
               <Text size="sm">Beneficiary Address</Text>
               <Text size="sm" weight={550}>1 Churchill Place, London E145HP</Text>
           </Box>
           <Box className="wired-bank-info">
               <Text size="sm">Sort Code</Text>
               <Text size="sm" weight={550}>20 00 00</Text>
           </Box>
           <Box className="wired-bank-info">
               <Text size="sm">IBAN</Text>
               <Text size="sm" weight={550}>GB15BARC20000056790588</Text>
           </Box>
           <Box className="wired-bank-info">
               <Text size="sm">SWIFT/BIC</Text>
               <Text size="sm" weight={550}>BARCGB22</Text>
           </Box>
           <Box className="wired-bank-info">
               <Text size="sm">Reference (Must be provided)</Text>
               <Text size="sm" weight={550}>16074</Text>
           </Box>
         </Box>
       </Stack>
   </Card>
);

WiredInstruction.defaultProps = {
    commitmentAmount: ""
}

export default WiredInstruction;
