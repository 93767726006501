import {
  IconDiscount2,
  IconGavel,
  IconLayoutGrid,
  IconLogout,
  IconSettings,
  IconStack2,
  IconTemplate,
  IconUser,
  IconWallet,
  TablerIcon,
  IconDashboard,
} from '@tabler/icons';

import {
  PATH_ACTIVE_DEALS,
  PATH_ALLOCATION_STAKING,
  PATH_ROOT,
  PATH_LOGOUT,
  PATH_SETTINGS,
  PATH_TOKEN_SALES,
  PATH_WALLET,
  PATH_SETTINGS_BASIC,
  PATH_SETTINGS_IDENTITY,
} from 'shared/constants';

export type NavigationOption = {
  label: string;
  icon: TablerIcon;
  subNavigation?: {
    [path: string]: Omit<NavigationOption, 'subNavigation'>;
  };
};

export const leftNavigationOptions: { [path: string]: NavigationOption } = {
  [PATH_ROOT]: {
    label: 'Dashboard',
    icon: IconLayoutGrid,
  },
  [PATH_WALLET]: {
    label: 'Wallet',
    icon: IconWallet,
  },
  [PATH_ACTIVE_DEALS]: {
    label: 'Active deals',
    icon: IconGavel,
  },
  [PATH_TOKEN_SALES]: {
    label: 'Token sales',
    icon: IconDiscount2,
  },
  [PATH_ALLOCATION_STAKING]: {
    label: 'Allocation staking',
    icon: IconStack2,
  },
  [PATH_SETTINGS]: {
    label: 'Account settings',
    icon: IconSettings,
    subNavigation: {
      [PATH_SETTINGS_BASIC]: {
        label: 'Basic info',
        icon: IconTemplate,
      },
      [PATH_SETTINGS_IDENTITY]: {
        label: 'Identity',
        icon: IconUser,
      },
    },
  },
};

export const topNavigationOptions: { [path: string]: { title: string; icon: TablerIcon } } = {
  [PATH_SETTINGS]: {
    title: 'Account settings',
    icon: IconUser,
  },
  [PATH_LOGOUT]: {
    title: 'Logout',
    icon: IconLogout,
  },
};
