import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { Box, Button, Text, Stack } from '@mantine/core';
import { useRequestSignUrlMutation, useHandleSubmissionMutation } from 'services/invest/invest.service';
import { InvestApi } from 'models/invest/invest.api.model';

import InvestmentPageSkeleton from './InvestmentPageSkeleton';
import NotFound from 'pages/error/404/components/NotFound';
import { useGetDealsQuery } from 'services/deals/deals.service';
import { PATH_LOGIN, PATH_DASHBOARD } from 'shared/constants';
import { getCurrencySymbol, formatNumber } from 'utils/currencySymbols';
import InvestmentForm from './InvestmentForm';
import TermsAndPayment from './TermsAndPayment';
import HelloDoc from 'components/HelloDocSign/HelloDoc';
import InvestmentInfo from 'components/Common/InvestmentInfo';
import './InvestmentPage.scss';
import FirstTimeInvestor from 'components/Common/FirstTimeInvestor';
import WiringComponent from 'components/Common/Wired/WiringComponent';
import { InvestmentStatus } from 'shared/enums';


const InvestmentComponent: React.FC = () => {
  const { dealId } = useParams<{ dealId: string }>();
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetDealsQuery(dealId || '');
  const { user: authenticatedUser, isLoading: isAuthLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const investments = useSelector((state: any) => state.auth0?.investments);
  const [investmentValue, setInvestmentValue] = useState<number | undefined>(investments?.[dealId || '']);
  const [checkboxValue, setCheckboxValue] = useState<string[]>([]);
  const [signUrl, setSignUrl] = useState<string | null>(null);
  const [signatureId, setSignatureId] = useState<string | null>(null);
  const [requestSignUrl, { isLoading: isRequesting }] = useRequestSignUrlMutation();
  const [handleSubmission] = useHandleSubmissionMutation();
  const [openWiringModel, setWiringModel] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthLoading) return;
    if (!isAuthenticated) {
      navigate(PATH_LOGIN);
    }
  }, [isAuthLoading, isAuthenticated, navigate]);


  const handleSuccess = async (responseData: any) => {
    setWiringModel(true);
  };

  const handleCancel = async () => {
    alert('Document signing cancelled');
  };

  const handleInvestmentCreation = async () => {
    const accessToken = await getAccessTokenSilently();
    const investmentDetails: InvestApi = {
      investmentPayload: {
        risk_factors: checkboxValue.includes('risk_factors'),
        subscription_agreement: checkboxValue.includes('subscription_agreement'),
        privacy_policy_consent: checkboxValue.includes('privacy_policy_consent'),
        tos_consent: checkboxValue.includes('privacy_policy_consent'),
        amount: investmentValue || 0,
        currency: 'USD',
      },
      companyName: data?.company_name || '',
      companyId: dealId || '',
      dealId: dealId || '',
      accessToken,
      userId: authenticatedUser?.sub ?? '',
    };
    try {
      const response = await requestSignUrl(investmentDetails).unwrap();
      setSignUrl(response.sign_url);
      setSignatureId(response.signature_id);
    } catch (err) {
      toast.error('Something, went wrong please contact our support team');
      console.error('Error requesting sign URL', err);
    } 
  };

  const handleError = (err: any) => {
    console.error(err)
  }

  const handleWiredClose = () => {
    setWiringModel(false);
    toast.info("Thanks for your commitment. Please wire the funds to complete the investment")
    navigate(PATH_DASHBOARD);
  }

  if (isLoading || isAuthLoading) {
    return <InvestmentPageSkeleton />;
  }

  if (error || !data) {
    return <NotFound />;
  }

  const currny = getCurrencySymbol(data.currency || 'USD');
  const investMent = formatNumber(investmentValue || 0);
  
  return (
    <Box className="company-details-container">
      <Box className="left-column">
        <InvestmentForm
          investmentValue={investmentValue}
          setInvestmentValue={setInvestmentValue}
          currency={currny}
          minInvestment={Number(data?.min_investment) || 0}
        />
        <FirstTimeInvestor />
        <TermsAndPayment checkboxValue={checkboxValue} setCheckboxValue={setCheckboxValue}  termSheet={data?.subscription_agreement_document_url || ''}/>
        <Stack mt="l">
          <Button onClick={handleInvestmentCreation} disabled={checkboxValue.length !== 3 || !investmentValue} loading={isRequesting}>
            Commit & E-Sign
          </Button>
          <Text color="dimmed" className="wired-commitment-message"  size="sm">
            Wire transfer of ${investMent} will be required upon commitment. Once e-signed your commitment amount cannot be cancelled or reduced.
          </Text>
        </Stack>
       

        <Text color="dimmed" size="sm"  my={50}>
          Tokenfolio and its affiliates do not provide investment advice to investors and the information on this page and the preceding deal page should not be relied upon as research, investment advice or a recommendation of any kind.
  
          <br />
          <br />

          Investment opportunities posted on this website are `private placement` of securities that are not publicly traded, and liquidity cannot be guaranteed. Investing in private companies may be considered highly speculative and involves a high degree of risk, including the risk of substantial loss of investment. Investors must be able to afford the loss of their entire investment.
        </Text>
      </Box>
      <Box className="company-deals-term">
        <InvestmentInfo 
          data={data}
          committedAmount={investmentValue || 0}
        />
      </Box>
      {signUrl && signatureId && (
        <HelloDoc
          signUrl={signUrl}
          signatureId={signatureId}
          onSuccess={handleSuccess}
          onError={handleError}
          onCancel={handleCancel}
        />
      )}

      {openWiringModel && 
        <WiringComponent 
          isOpen={openWiringModel}
          handleClose={handleWiredClose}
          activeStage={InvestmentStatus.COMMITTED.rank}
          amount={investmentValue}
          currency={currny}
          data={data}
        />}
    </Box>
  );
};

export default InvestmentComponent;


