import React from 'react';
import { Stack, Title, Checkbox, Text, Anchor } from '@mantine/core';

interface TermsAndPaymentProps {
  checkboxValue: string[];
  setCheckboxValue: (value: string[]) => void;
  termSheet: string;
}

const TermsAndPayment: React.FC<TermsAndPaymentProps> = ({ checkboxValue, setCheckboxValue, termSheet }) => (
  <Stack className="invest-termspayment" mt="md">
    <Title order={3} className="invest-termspayment--title">Terms & payment</Title>
    <Checkbox.Group value={checkboxValue} onChange={setCheckboxValue} orientation="vertical">
      <Checkbox
        radius="xs"
        value="subscription_agreement"
        label={
          <Text fz="md">
            I agree to the terms and provisions of the <Anchor size="sm" href={termSheet} target="_blank" className="highlighted-anchor">Subscription Agreement</Anchor> 
          </Text>
      }
      />
      <Checkbox
        radius="xs"
        value="privacy_policy_consent"
        label={
          <Text fz="md">
            I agree to the <Anchor size="sm" href="https://www.tokenfolio.co/terms-of-service" target="_blank" className="highlighted-anchor">Terms of Service</Anchor> &{' '}
            <Anchor size="sm" href="https://www.tokenfolio.co/privacy-policy" target="_blank" className="highlighted-anchor">Privacy Policy</Anchor>
          </Text>
        }
      />
      <Checkbox
        radius="xs"
        value="risk_factors"
        label={
          <Text fz="md">
            I understand that this investment involves substantial risks and acknowledge risks outlined in the{' '}
            <Anchor size="sm" href="https://www.tokenfolio.co/risks" target="_blank" className="highlighted-anchor">Risk Factors</Anchor>
          </Text>
        }
      />
    </Checkbox.Group>
  </Stack>
);

export default TermsAndPayment;
