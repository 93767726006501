// eslint-disable-next-line import/prefer-default-export
export enum ErrorCode {
  NOT_FOUND = 404,
}

export enum ActionType {
  Submit = 'submit',
  Cancel = 'cancel',
  Error = 'error',
}

export enum DocumentStatus {
  Pending = 'pending',
  Signed = 'signed',
  Canceled = 'canceled',
}

export const InvestmentStatus = {
  COMMITTED: { label: 'Committed', value: 'COMMITTED', rank: 1 },
  WIRED: { label: 'Wired', value: 'WIRED', rank: 2  },
  FUNDS_RECEIVED: { label: 'Funds Received', value: 'FUNDS_RECEIVED', rank: 3 },
  COMPLETED: { label: 'Completed', value: 'COMPLETED', rank: 4  },
} as const;